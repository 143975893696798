<template>
  <div v-if="profile && !orders">
    <h3 class="not-margin">Account Information</h3>
    <v-form ref="form" lazy-validation>
      <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" required></v-text-field>
      <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name" required></v-text-field>
      <v-text-field disabled v-model="email" label="E-mail" required></v-text-field>
      <v-text-field v-model="address" label="Address"></v-text-field>
      <v-text-field v-model="phone" label="Phone Number"></v-text-field>
      <v-text-field v-model="phone2" label="Alternate Number"></v-text-field>
      <vs-row>
        <v-btn rounded dark @click.prevent="updateProfile()">
          Save Changes
        </v-btn>
      </vs-row>
      <Alert :snackbar="alert" :text="alertMessage" :color="alertColor" @updateSnackbar="closeSnackbar" />
    </v-form>
  </div>
</template>
<script>
import { COLORS } from '@/plugins/branding.js';
export default {
  name: 'YourProfile',
  props: ['profile', 'orders'],
  components: {
    Alert: () => import('@/components/Alert.vue'),
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
    },
    COLORS,
    alert: false,
    alertColor: null,
    alertMessage: null,
  }),
  computed: {
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit('changeFirstName', payload);
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit('changeLastName', payload);
      },
    },
    email() {
      return this.$store.state.profileEmail;
    },
    address: {
      get() {
        return this.$store.state.profileAddress;
      },
      set(payload) {
        this.$store.commit('changeAddress', payload);
      },
    },
    phone: {
      get() {
        return this.$store.state.profilePhone;
      },
      set(payload) {
        this.$store.commit('changePhone', payload);
      },
    },
    phone2: {
      get() {
        return this.$store.state.profilePhone2;
      },
      set(payload) {
        this.$store.commit('changePhone2', payload);
      },
    },
  },
  methods: {
    closeSnackbar(bool) {
      this.alert = bool;
    },
    updateProfile() {
      try {
        this.$store.dispatch('updateUserSettings');
        this.alertColor = COLORS.GREEN;
        this.alertMessage = 'Your profile has been updated successfully';
        this.alert = true;
      } catch (err) {
        this.alertColor = COLORS.RED;
        this.alertMessage = 'Your profile could not be updated, please try again later';
        this.alert = true;
      }
    },
  },
};
</script>
